import React, { useState } from 'react';
interface LoadingMaskContextType {
  addLoadingMask: (params: { show: boolean }) => void;
}
interface LoadingMaskProviderType {
  children: React.ReactNode;
}
const LoadingMask = ({ display }: { display: boolean }) => {
  return (
    <div>
      {display && (
        <div className="fixed bottom-0 w-full h-full bg-gray-300 bg-opacity-50 z-[1001]">
          <div className="absolute inset-1/2">
            <div className="rounded-full border-white border-4 h-16 w-16">
              <div className="relative -top-1 -left-1 border-t-transparent border-solid animate-spin rounded-full border-gray-600 border-4 h-16 w-16"></div>
            </div>
            <span>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

const LoadingMaskContext = React.createContext<LoadingMaskContextType>(null!);

export const LoadingMaskProvider = ({ children }: LoadingMaskProviderType) => {
  const [status, setStatus] = useState<boolean>(false);
  const addLoadingMask = ({ show = false }: { show: boolean }) => {
    setStatus(show);
  };
  return (
    <LoadingMaskContext.Provider value={{ addLoadingMask }}>
      {children}
      <LoadingMask display={status}></LoadingMask>
    </LoadingMaskContext.Provider>
  );
};

export const useLoadingMask = () => React.useContext(LoadingMaskContext);
