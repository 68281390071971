const Loading = () => {
  return (
    <div className="fixed inset-0 w-full h-screen overflow-hidden z-50 flex flex-col items-center justify-center ml-16">
      <p className="text-center text-gray-700 text-xl font-semibold my-4">Loading...</p>
      <progress className="progress w-3/5"></progress>
    </div>
  );
};

export default Loading;
