import { AxiosResponse } from 'axios';
import { cbtUserUpdate } from 'models/cbt-user-update';
import { GetDevices } from 'models/get-devices';
import { Me } from 'models/me';
import { Oauth2, SignIn } from 'models/oauth2';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'utils/axiosInstance';
import { getItem, setItem } from 'utils/localStorageHelper';
import { useConfig } from './ConfigProvider';

const AuthContext = React.createContext<{
  token: string;
  userId: string;
  password: string;
  user: Me;
  signInByUser: (params: SignIn) => void;
  signInToRemoteDevice: (deviceId: string) => Promise<AxiosResponse<Oauth2>>;
  storageClearThenGoBack: () => void;
  logout: () => void;
  getUser: () => void;
  getDeviceList: (userId: string) => Promise<AxiosResponse<GetDevices[]>>;
  putCbtUserUpdate: (cbtConfig: cbtUserUpdate) => Promise<AxiosResponse>;
  deleteDevice: (deviceId: string) => Promise<AxiosResponse>;
  getCbtFirmwareBySerialNumber: (deviceId: string, remoteToken: string) => Promise<AxiosResponse>;
  postCbtFirmwareBySerialNumber: (deviceId: string, remoteToken: string) => Promise<AxiosResponse>;
}>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { cloudUrl, ucentralSecUrl, cloudAccessUrl } = useConfig();
  const storageToken = getItem('access_token') ?? '';
  const storageUserId = getItem('userId') ?? '';
  const [token, setToken] = useState<string>(storageToken);
  const [userId, setUserId] = useState<string>(storageUserId);
  const [password, setPassword] = useState<string>('');
  const [user, setUser] = useState<Me>({} as Me);

  useEffect(() => {
    if (token !== '' && ucentralSecUrl !== undefined) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, ucentralSecUrl]);

  const signInByUser = (params: SignIn) => {
    setPassword(params.password);
    return axiosInstance.post<Oauth2>(`${ucentralSecUrl}/api/v1/oauth2`, params).then((response: AxiosResponse<Oauth2>) => {
      const { access_token, username } = response.data;
      setItem('access_token', access_token);
      setToken(access_token);
      setItem('userId', username);
      setUserId(username);
    });
  };

  const signInToRemoteDevice = (deviceId: string) =>
    axiosInstance.get<Oauth2>(`${ucentralSecUrl}/api/v1/remoteOauth2/${deviceId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const logout = () => {
    axiosInstance
      .delete(`${ucentralSecUrl}/api/v1/oauth2/${token}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        storageClearThenGoBack();
      });
  };

  const storageClearThenGoBack = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace('/');
  };

  const getUser = () =>
    axiosInstance
      .get(`${ucentralSecUrl}/api/v1/oauth2?me=true`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<Me>) => {
        setUser(response.data);
      })
      .catch(() => {});

  const getDeviceList = (userId: string): Promise<AxiosResponse<GetDevices[]>> => {
    return axiosInstance.get(`${cloudAccessUrl}/api/v1/getDevices/${userId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const putCbtUserUpdate = (cbtConfig: cbtUserUpdate): Promise<AxiosResponse> =>
    axiosInstance.put(`${ucentralSecUrl}/api/v1/user/${user.id}`, cbtConfig, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const deleteDevice = (deviceId: string): Promise<AxiosResponse> =>
    axiosInstance.delete(`${ucentralSecUrl}/api/v1/removeDevice/${deviceId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  const getCbtFirmwareBySerialNumber = (deviceId: string, remoteToken: string): Promise<AxiosResponse> =>
    axiosInstance.get(`https://${deviceId}.${cloudUrl}/owgw/api/v1/cbtFirmware/${deviceId}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${remoteToken}`,
      },
    });

  const postCbtFirmwareBySerialNumber = (deviceId: string, remoteToken: string): Promise<AxiosResponse> =>
    axiosInstance.post(
      `https://${deviceId}.${cloudUrl}/owgw/api/v1/cbtFirmware/${deviceId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${remoteToken}`,
        },
      }
    );

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        password,
        user,
        logout,
        signInByUser,
        signInToRemoteDevice,
        storageClearThenGoBack,
        getUser,
        getDeviceList,
        putCbtUserUpdate,
        deleteDevice,
        getCbtFirmwareBySerialNumber,
        postCbtFirmwareBySerialNumber,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
