import remove from 'assets/remove.svg';
import React, { useEffect, useRef, useState } from 'react';

interface ToastContextType {
  addToast: (params: ToastType) => void;
}
interface ToastsProviderType {
  children: React.ReactNode;
}
interface ToastType {
  title?: string;
  message: string;
  duration?: number; // defultValue = 3000
  color?: '' | 'bg-green-200' | 'bg-rose-200' | 'bg-cyfi-error' | 'bg-cyfi-info' | 'bg-cyfi-success' | 'bg-cyfi-warning' | 'bg-cyfi-primary'; // defultValue = ''
}

const Toast = ({ title, message, duration, color }: ToastType) => {
  const [show, setShow] = useState<boolean>(true);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    if (show === true && duration) {
      setAutohide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const close = () => {
    clearTimeout(timeout.current);
    setShow(false);
  };

  const setAutohide = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setShow(false);
    }, duration);
  };

  return (
    <>
      {show && (
        <div className={`flex items-center w-full max-w-xs p-4 text-gray-500  rounded-lg shadow mb-4 ${color}`}>
          <div className="flex-col items-start">
            {title && <span className="font-semibold">{title}</span>}
            <span className="ml-3 text-sm font-normal">{message}</span>
          </div>
          <button className="btn btn-ghost ml-auto hover:bg-transparent" onClick={close}>
            <img src={remove} className="w-4 h-4" alt="close" />
          </button>
        </div>
      )}
    </>
  );
};

const ToastContext = React.createContext<ToastContextType>(null!);

export const ToastProvider = ({ children }: ToastsProviderType) => {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  const addToast = ({ title, message, duration = 3000, color = '' }: ToastType) => {
    setToasts([...toasts, { title, message, duration, color }]);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {/* toast z-index 需要比 modal z-index:999 高 */}
      <div className="toast toast-center flex items-center w-full max-w-xs" style={{ maxWidth: '33vw', zIndex: 1000 }}>
        {toasts.map((toast, index) => {
          return <Toast key={index} duration={toast.duration} color={toast.color} title={toast.title} message={toast.message}></Toast>;
        })}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
