import { useAuth } from 'contexts/AuthProvider';
import { lazy } from 'react';

const Login = lazy(() => import('../pages/Login'));

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { token } = useAuth();

  if (!token) {
    return <Login />;
  }
  return children;
};

export default RequireAuth;
