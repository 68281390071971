import React, { useEffect, useState } from 'react';
import { getItem, setItem } from 'utils/localStorageHelper';

interface ConfigContextType {
  cloudUrl: string;
  ucentralSecUrl: string;
  cloudAccessUrl: string;
  defaultUserEmail: string;
}

interface ConfigProviderType {
  children: React.ReactNode;
}

const ConfigContext = React.createContext<ConfigContextType>(null!);

export const ConfigProvider = ({ children }: ConfigProviderType) => {
  const [defaultConfig, setDefaultConfig] = useState<ConfigContextType>({} as ConfigContextType);

  const getDefaultConfig = async () => {
    fetch(`${process.env.PUBLIC_URL}/config.json`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response: Response) => response.json())
      .then((json: ConfigContextType) => {
        setDefaultConfig(json);
        setItem('defaultConfig', JSON.stringify(json));
      })
      .catch();
  };

  useEffect(() => {
    const storageDefaultConfig = getItem('defaultConfig');
    if (storageDefaultConfig) {
      setDefaultConfig(JSON.parse(storageDefaultConfig));
    } else {
      getDefaultConfig();
    }
  }, []);

  return <ConfigContext.Provider value={defaultConfig}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => React.useContext(ConfigContext);
