import axios from 'axios';
import axiosRetry from 'axios-retry';

export const AUTH_EXPIRED_TOKEN_CODE = 9;
export const AUTH_INVALID_TOKEN_CODE = 8;

const axiosInstance = axios.create();
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

axiosInstance.defaults.timeout = 160000;
axiosInstance.defaults.headers.get.Accept = 'application/json';
axiosInstance.defaults.headers.post.Accept = 'application/json';

axiosInstance.interceptors.response.use(undefined, (error: any) => {
  switch (error.response.status) {
    case 401:
      break;
    case 403:
      if (error.response.data?.ErrorCode === 13) {
        let retries = localStorage.getItem('sec_retries') ? Number(localStorage.getItem('sec_retries')) : 0;
        retries += 1;
        localStorage.setItem('sec_retries', retries.toString());
      }
      if (error.response.data?.ErrorCode === AUTH_EXPIRED_TOKEN_CODE || error.response.data?.ErrorCode === AUTH_INVALID_TOKEN_CODE) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('gateway_endpoints');
        sessionStorage.clear();
        window.location.href = '/';
      }
      break;
    default:
      break;
  }
  return Promise.reject(error);
});

export default axiosInstance;
