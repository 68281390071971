import RequireAuth from 'components/RequireAuth';
import { AuthProvider } from 'contexts/AuthProvider';
import { ConfigProvider } from 'contexts/ConfigProvider';
import { LoadingMaskProvider } from 'contexts/LoadingMaskProvider';
import { ModalProvider } from 'contexts/ModalProvider';
import { ToastProvider } from 'contexts/ToastProvider';
import Loading from 'pages/Loading';
import { lazy, Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

const Layout = lazy(() => import('./layout'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));

const App = () => {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loading />}>
          <RequireAuth>
            <Layout />
          </RequireAuth>
        </Suspense>
      ),
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: '/profile',
          element: (
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          ),
        },
      ],
    },
  ];
  const element = useRoutes(routes);
  return (
    <ConfigProvider>
      <LoadingMaskProvider>
        <ToastProvider>
          <AuthProvider>
            <ModalProvider>{element}</ModalProvider>
          </AuthProvider>
        </ToastProvider>
      </LoadingMaskProvider>
    </ConfigProvider>
  );
};

export default App;
