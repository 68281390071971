import { ReactComponent as Success } from 'assets/check-circle.svg';
import { ReactComponent as Information } from 'assets/exclamation-circle-fill.svg';
import { ReactComponent as Exclamation } from 'assets/exclamation-fill.svg';
import React, { useEffect, useState } from 'react';

interface ModalContextType {
  addConfirmModal: (params: ConfirmModalType) => void;
  addRadialProgressModal: (params: RadialProgressModalType) => void;
  addCustomModal: (params: CustomModalType) => void;
  closeCustomModal: () => void;
}
interface ModalsProviderType {
  children: React.ReactNode;
}
interface ConfirmModalType {
  open: boolean;
  id: string;
  btnOk: string;
  btnCancel?: string;
  type: 'warning' | 'info' | 'success';
  title?: string;
  message: string | React.ReactNode;
  onClickOk: () => void;
  onClickCancel?: () => void;
  totalCountDown?: number; //unit is microsecond
}
interface RadialProgressModalType {
  open: boolean;
  title: string;
  message: string | React.ReactNode;
  tip?: string | React.ReactNode;
  totalTime: number; //unit is microsecond
  finishFunction?: () => void;
}

interface CustomModalType {
  open: boolean;
  content: React.ReactNode;
  width?: string | number;
  height?: string | number;
}
const ModalContext = React.createContext<ModalContextType>(null!);

export const ModalProvider = ({ children }: ModalsProviderType) => {
  const [confirmModal, setConfirmModal] = useState<ConfirmModalType>({} as ConfirmModalType);
  const [radialProgressModal, setRadialProgressModal] = useState<RadialProgressModalType>({} as RadialProgressModalType);
  const [customModal, setCustomModal] = useState<CustomModalType>({} as CustomModalType);

  const addConfirmModal = ({ open, id, btnOk, btnCancel, type, title, message, onClickOk, onClickCancel, totalCountDown }: ConfirmModalType) => {
    setConfirmModal({ open, id, btnOk, btnCancel, type, title, message, onClickOk, onClickCancel, totalCountDown });
  };

  const addRadialProgressModal = ({ open, title, message, tip, totalTime, finishFunction }: RadialProgressModalType) => {
    setRadialProgressModal({ open, title, message, tip, totalTime, finishFunction });
  };

  const addCustomModal = ({ open, content, width, height }: CustomModalType) => {
    setCustomModal({ open, content, width, height });
  };
  const closeCustomModal = () => {
    setCustomModal({} as CustomModalType);
  };

  const [val, setVal] = useState(0);
  const [count, setCount] = useState(-1);
  const style = { '--value': val, '--size': '6rem', '--thickness': '6px' } as React.CSSProperties;
  let countdownTimout: NodeJS.Timeout;

  useEffect(() => {
    if (confirmModal.open && !radialProgressModal.open) {
      if (confirmModal.totalCountDown && count === -1) {
        setCount(confirmModal.totalCountDown / 1000);
        setVal(0);
      }
      if (confirmModal.totalCountDown && val < 100 && count > 0) {
        const step = 100 / (confirmModal.totalCountDown / 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        countdownTimout = setTimeout(() => {
          setVal(val + step);
          setCount(count - 1);
        }, 1000);
      }
      if (count === 0) {
        setTimeout(() => {
          clickConfirmModalOk();
        }, 500);
      }
    }
    if (radialProgressModal.open && !confirmModal.open) {
      if (val < 100) {
        setTimeout(() => {
          setVal(val + 1);
        }, radialProgressModal.totalTime / 100);
      } else {
        setTimeout(() => {
          setRadialProgressModal({} as RadialProgressModalType);
          setVal(0);
          radialProgressModal.finishFunction && radialProgressModal.finishFunction();
        }, 500);
      }
    }
  }, [val, count, confirmModal, radialProgressModal]);

  const clickConfirmModalOk = () => {
    setConfirmModal({} as ConfirmModalType);
    clearTimeout(countdownTimout);
    setCount(-1);
    setVal(0);
    confirmModal.onClickOk();
  };

  const clickConfirmModalCancel = () => {
    setConfirmModal({} as ConfirmModalType);
    clearTimeout(countdownTimout);
    setCount(-1);
    setVal(0);
    confirmModal.onClickCancel && confirmModal.onClickCancel();
  };

  return (
    <ModalContext.Provider value={{ addConfirmModal, addRadialProgressModal, addCustomModal, closeCustomModal }}>
      {children}
      {confirmModal.open && (
        <div>
          <input type="checkbox" id={confirmModal.id} className="modal-toggle" checked={true} readOnly />
          <div className="modal">
            <div className="modal-box">
              <div className={confirmModal.title ? '' : 'flex'}>
                {confirmModal.type === 'info' ? (
                  <Information className="w-10 h-10 inline mr-2 rounded-full fill-blue-400 bg-blue-50 p-3" />
                ) : confirmModal.type === 'warning' ? (
                  <Exclamation className="w-10 h-10 inline mr-2 rounded-full fill-yellow-400 bg-yellow-50 p-2" />
                ) : (
                  <Success className="w-10 h-10 inline mr-2 rounded-full fill-emerald-600 bg-green-50 p-2" />
                )}
                {confirmModal.title && <h3 className="font-bold text-lg inline">{confirmModal.title}</h3>}
                <div className={`flex flex-col` + (confirmModal.totalCountDown ? ' items-center' : '')}>
                  <p className={confirmModal.title ? 'py-4 pl-12 pr-2' : 'px-2 max-w-[400px]'}>{confirmModal.message}</p>
                  {confirmModal.totalCountDown && (
                    <div className="radial-progress" style={style}>
                      <div className="flex justify-center items-center w-[92px] h-[92px] rounded-full border-gray-300 border-2">{count}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-action inline float-right mr-2" onClick={clickConfirmModalOk}>
                <label htmlFor={confirmModal.id} className="btn btn-primary">
                  {confirmModal.btnOk}
                </label>
              </div>
              {confirmModal.btnCancel && (
                <div className="modal-action inline float-right mr-2" onClick={clickConfirmModalCancel}>
                  <label htmlFor={confirmModal.id} className="btn btn-outline">
                    {confirmModal.btnCancel}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {radialProgressModal.open && (
        <div>
          <input type="checkbox" className="modal-toggle" checked={radialProgressModal.open} readOnly />
          <div className="modal">
            <div className="modal-box flex flex-col items-center space-y-1">
              <h3 className="font-bold text-lg">{radialProgressModal.title}</h3>
              <p className="pb-4">{radialProgressModal.message}</p>
              <div className="radial-progress" style={style}>
                <div className="flex justify-center items-center w-[92px] h-[92px] rounded-full border-gray-300 border-2">{val}%</div>
              </div>
              {radialProgressModal.tip && (
                <div className="flex items-center">
                  <Exclamation className="fill-yellow-500 mr-2 w-8" />
                  <p className="text-gray-500">{radialProgressModal.tip}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {customModal.open && (
        <div>
          <input type="checkbox" id={confirmModal.id} className="modal-toggle" checked={customModal.open} readOnly />
          <div className="modal">
            <div className="modal-box" style={{ width: customModal.width, height: customModal.height, maxWidth: customModal.width }}>
              {customModal.content}
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => React.useContext(ModalContext);
